import client from "../../client.js";
import Head from "next/head";
import Hero from "../../components/hero/hero.js";
import Carousel from "../../components/carousel/carousel.js";
import Filters from "../../components/channelfilters/filters.js";
import Accordion from "../../components/accordion/accordion";
import TextCarousel from "../../components/carousel/textCarousel.js";
import Divider from "../../components/layout/divider.js";
import VideoBG from "../../components/layout/video_bg.js";
import NewsGrid from "../../components/grids/news.js";
import Rewards from "../../components/rewards/rewards.js";
import Signage from "../../components/signage/signage.js";
import Hand from "../../components/hand/hand.js";
import GradientBG from "../../components/layout/gradientBackground.js";
import Map from "../../components/map/index.js";
import { useRef, useEffect } from "react";
import zenscroll from "zenscroll";
import { getImageAsset } from "@sanity/asset-utils";
import sanityProject from "../../components/utilities/sanityProject";
import { fbEvent } from "@rivercode/facebook-conversion-api-nextjs";

function Page({ modalClick, pagedata, newsdata }) {
  useEffect(() => {
    fbEvent({
      eventName: "PageView", // ViewContent, AddToCart, InitiateCheckout or Purchase
      products: [
        {
          sku: "player",
          quantity: 1,
        },
      ],
      value: 100,
      currency: "USD",
      enableStandardPixel: true, // default false (Require Facebook Pixel to be loaded, see step 2)
    });
  }, []);

  // scroll to rewards section
  const scrollToRewards = () => zenscroll.toY(rewardsEl.current.offsetTop);
  const rewardsEl = useRef();

  let assetInfo, metaImgUrl;
  if (pagedata.openGraphImage) {
    assetInfo = getImageAsset(pagedata.openGraphImage, sanityProject);
    metaImgUrl = assetInfo.url;
  }

  return (
    <div className="container">
      <Head>
        <title>{pagedata.metatitle}</title>
        <meta name="description" content={pagedata.description} />
        <meta property="og:title" content={pagedata.metatitle} key="title" />
        <meta
          property="og:description"
          content={pagedata.description}
          key="description"
        />
        <meta property="og:image" content={metaImgUrl} />
        <meta name="referrer" content="no-referrer-when-downgrade"></meta>
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://www.loop.tv/business" />
      </Head>

      {pagedata.hero.status != "hidden" && (
        <Hero click={() => scrollToRewards()} content={pagedata.hero} />
      )}
      <GradientBG>
        {pagedata.hand.status != "hidden" && (
          <Hand content={pagedata.hand} modalClick={modalClick} />
        )}

        {pagedata.testimonialsBlock.status != "hidden" && (
          <TextCarousel content={pagedata.testimonialsBlock} />
        )}

        {pagedata.signage.status != "hidden" && (
          <Signage modalClick={modalClick} content={pagedata.signage} />
        )}
        {pagedata.statsmap.status != "hidden" && (
          <Map content={pagedata.statsmap} />
        )}
      </GradientBG>
      <Divider />
      {pagedata.channelFilters.status != "hidden" && (
        <Filters content={pagedata.channelFilters} />
      )}
      {pagedata.accordion?.status != "hidden" && (
        <Accordion data={pagedata.accordion} />
      )}
      {pagedata.carouselLogos.status != "hidden" && (
        <Carousel content={pagedata.carouselLogos} />
      )}
      {pagedata.rewards.status != "hidden" && (
        <Rewards
          reference={rewardsEl}
          modalClick={modalClick}
          content={pagedata.rewards}
        />
      )}
      <Divider />
      {pagedata.newsBlock.status != "hidden" && <NewsGrid data={newsdata} />}
      <VideoBG video_file={pagedata.video_file} />
    </div>
  );
}

export async function getStaticProps() {
  // get all page specific component data
  const pagedata = await client.fetch(
    '*[_type == "page" && slug.current == "businesses"][0]{video_file, metatitle, description, openGraphImage,hero,hand,signage,statsmap,channelFilters,accordion,newsBlock,testimonialsBlock{status,testimonials[]->},carouselLogos{...,text[]{...,markDefs[]{...,_type == "internalLink" => {"slug": @.reference->slug}}}},rewards{...,text[]{...,markDefs[]{...,_type == "internalLink" => {"slug": @.reference->slug}}}}}'
  );

  // get 3 most recent news article based on page news category selection
  let newsCat = pagedata.newsBlock.category;
  let newsdata;
  if (newsCat === "all") {
    newsdata = await client.fetch(
      '*[_type == "news" && releaseDate != null]{..., category->{...}} | order(releaseDate desc) [0..2]'
    );
  } else {
    newsdata = await client.fetch(
      '*[_type == "news" && releaseDate != null && category == "' +
        newsCat +
        '"]{..., category->{...} | order(releaseDate desc) [0..2]'
    );
  }

  return {
    props: {
      pagedata,
      newsdata,
    },
    revalidate: 10,
  };
}

export default Page;
