import styles from "./index.module.scss";
import AccordionItem from "./accordionItem.js";
import React from "react";
import { useState } from "react";

export default function Accordion({ data }) {
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => () => {
    setExpanded(panel === expanded ? null : panel);
  };

  if (!data) {
    return null;
  }

  return (
    <div className={styles.accordion}>
      {data.items.map((item, index) => (
        <AccordionItem
          key={index}
          id={`panel${index}`}
          title={item.title}
          content={item.text}
          expanded={expanded === `panel${index}`}
          handleChange={handleChange(`panel${index}`)}
        />
      ))}
    </div>
  );
}
