import styles from "./index.module.scss";
import { PortableText } from "@portabletext/react";
import myPortableTextComponents from "../utilities/portableTextStyle";
export default function AccordionItem({
  index,
  title,
  content,
  expanded,
  handleChange,
}) {
  return (
    <div
      className={`${styles.accordion_item} ${
        expanded ? styles.open : styles.closed
      }`}
    >
      <div className={styles.title} onClick={handleChange}>
        <h2>{title}</h2>
        <div className={styles.icon_wrap}>
          <svg
            className={styles.icon}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
          </svg>
        </div>
      </div>
      <div className={styles.answer}>
        <PortableText value={content} components={myPortableTextComponents} />
      </div>
    </div>
  );
}
